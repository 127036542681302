const initBlock = ( $block ) => {
	/**
	 * Initialize the scrollbar.
	 */
	app.loadPerfectScrollbar( ( PerfectScrollbar, defaultOptions ) => {
		$block.find( '.js-scrollbar' ).each( ( _, element ) => {
			new PerfectScrollbar( element, defaultOptions );
		} );
	} );
};

app.loadBlock( initBlock, 'block-seo-content', '.js-section-seo-content' );
